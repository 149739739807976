$( document ).ready( function() {
    $( '.mr-showMore-text-hotelsListView' ).on( 'click', function() {
        $( '.list-view-wrapper:nth-child(n+6)' ).toggle();
        if ( $( this ).text() == 'SHOW MORE' ) {
            $( this ).text( 'SHOW LESS' );
            $( '.mr-showMore-hotels-arrow-img' ).css( {
                'transform': 'rotate(' + 180 + 'deg)'
            } );
        } else {
            $( this ).text( 'SHOW MORE' );
            $( '.mr-showMore-hotels-arrow-img' ).css( {
                'transform': 'rotate(' + 180 + 'deg)'
            } );
        }
    } )
} );